import permissions from "@/router/userPermissions";

export default [
  {
    path: "session/:id/initial-signatures",
    component: () => import("@/modules/cardioguard-trainer/views/TrainerSessionInitialSignatures"),
    name: "trainerSessionInitialSignatures",
    meta: {
      auth: permissions.scopes.trainer,
      title: "Documentación inicial a firmar",
      serviceName: "trainer-session-signatures",
    },
  },
  {
    path: "session/:id/final-signatures",
    component: () => import("@/modules/cardioguard-trainer/views/TrainerSessionFinalSignatures"),
    name: "trainerSessionFinalSignatures",
    meta: {
      auth: permissions.scopes.trainer,
      title: "Finalizar sesión y firmas",
      serviceName: "trainer-session-signatures",
    },
  },
];
