import { crudMdActions } from "@/services/crud-md-actions";
import apiClient from "@/services/ApiClient";

const name = "finished-training-course-inscriptions";

export default {
  name,
  ...crudMdActions(name, "trainer/finished-training-course-inscriptions"),
  getFormRecordByInscription: inscriptionId => {
    return apiClient.get(`trainer/form-records/training-course-inscription/${inscriptionId}`);
  },
};
