import permissions from "@/router/userPermissions";
import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  {
    path: "training-course-sessions/:id",
    name: "trainingCourseSessionsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "training-course-sessions",
      title: "Sesión",
    },
  },
];
