<template>
  <v-app>
    <notifications position="bottom left" width="400px" classes="prd-notification" />

    <top-bar></top-bar>

    <!-- APP Content -->
    <v-main :class="{ 'prd-main-full-width': !navDrawer }">
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ServiceFactory from "@/services/ServiceFactory";

export default {
  components: {
    TopBar: () => import("@/layouts/intranet-sign-mode/components/TopBar"),
  },
  props: {
    fluid: { type: Boolean, default: true },
  },
  data: () => ({
    notification: { position: "bottom left", duration: 6000 },
  }),
  created() {
    this.$vuetify.theme.dark = this.$store.state.userSettings.darkMode;
  },
  updated() {
    if (this.$route.query.forbidden) {
      const newQuery = { ...this.$route.query };
      delete newQuery.forbidden;
      this.$router.replace({ query: newQuery });

      this.$notifyError(
        "No tienes permisos",
        "No tienes suficientes permisos para acceder a esta página. Si crees que esto es un error, por favor, consúltalo con el responsable del sistema."
      );
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        // UPDATE title
        let title = "";
        const paramId = this.$route.params.id;
        if (paramId) title += `#${paramId} | `;
        title += to.meta?.title;
        if (title) {
          document.title = to.meta.title;
          this.$store.dispatch("updateTitle", title);
        }

        to.meta.hideNavDrawer || this.$vuetify.breakpoint.smAndDown
          ? (this.navDrawer = false)
          : (this.navDrawer = true);

        // UPDATE Store currentService
        const currentServiceName = this.$store.state.currentService?.name;
        const serviceName = to.meta?.serviceName;
        const newService = serviceName ? ServiceFactory.get(serviceName) : {};

        if (!from || currentServiceName !== newService.name) {
          this.$store.dispatch("updateCurrentService", newService);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.prd-notification {
  padding: 10px;
  margin: 10px;
  font-size: 0.9em;
  color: white;
  background: #195ae0 !important;
  border-left: 5px solid #1343a7 !important;
  &.warn {
    background: #ffbf3f !important;
    border-left-color: #e39e37 !important;
  }
  &.error {
    background: #ff4344 !important;
    border-left-color: #bb2e31 !important;
  }
  &.success {
    background: #00bba7 !important;
    border-left-color: #018d7e !important;
  }
}

.v-tab {
  text-transform: unset !important;
  &--active {
    font-weight: bold !important;
  }
}

.prd-flex-1 {
  display: flex;
  flex: 1;
  max-width: 100%;
}
.prd-maxwidth-100 {
  max-width: 100%;
}
</style>
