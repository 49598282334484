import crudUpdateMd from "@/components/crud-md/actions/Edit";
import permissions from "@/router/userPermissions";

export default [
  {
    path: "client-notices",
    name: "clientNotices",
    component: () => import("@/modules/cardioguard-admin/views/ClientNoticesCrud"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "client-notices",
      title: "Avisos",
    },
  },
  {
    path: "client-notices/:id",
    name: "clientNoticesEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["clientNotices"],
      serviceName: "client-notices",
      title: "Aviso",
    },
  },
];
