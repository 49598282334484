import { crudMdActions } from "@/services/crud-md-actions";
import { getListActions } from "@/services/crud-md-actions/getListActions";
import { createActions } from "@/services/crud-md-actions/createActions";
import apiClient from "@/services/ApiClient";

const name = "documents";

export default {
  name,
  ...crudMdActions(name),
  tab: { getListActions, createActions },
  regenerateDocs: documentId => {
    return apiClient.post(`/documents/${documentId}/regenerateCertificate`);
  },
};
