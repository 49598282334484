import authService from "@/services/AuthService";

import adminServices from "@/modules/cardioguard-admin/services/index";
import trainerServices from "@/modules/cardioguard-trainer/services/index";
import { isUrlScope } from "@/utils/helpers";

let services = [];

if (isUrlScope("/admin")) {
  services = [authService, ...adminServices];
} else if (isUrlScope("/trainer")) {
  services = [authService, ...trainerServices];
} else {
  services = [authService];
}

export default {
  get: name => services.find(s => s.name === name),
};
