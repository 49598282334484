import permissions from "@/router/userPermissions";
import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  {
    path: "training-course-inscriptions/:id",
    name: "trainingCourseInscriptionsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "training-course-inscriptions",
      title: "Participante",
    },
  },
];
