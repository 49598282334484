import permissions from "@/router/userPermissions";
import studentsRoutesTabs from "@/modules/cardioguard-admin/router/studentsRoutesTabs";

export default [
  {
    path: "students",
    name: "students",
    component: () => import("@/modules/cardioguard-admin/views/studentsCrud"),
    meta: { auth: permissions.scopes.admin, serviceName: "students", title: "Alumnos" },
  },
  {
    path: "students/:id/edit",
    name: "studentsEdit",
    component: () => import("@/modules/cardioguard-admin/views/studentsCrud/StudentsCrudEdit"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["students"],
      serviceName: "students",
      tabs: studentsRoutesTabs,
      title: "Datos de alumno",
    },
  },

  {
    path: "students/:id/training-course-inscriptions",
    component: () =>
      import(
        "@/modules/cardioguard-admin/views/studentsCrud/StudentsCrudTrainingCourseInscriptions"
      ),
    name: "students-trainingCourseInscriptions",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["students"],
      title: "Formaciones",
      tabs: studentsRoutesTabs,
      serviceName: "training-course-inscriptions",
    },
  },

  {
    path: "students/:id/documents",
    component: () => import("@/modules/cardioguard-admin/views/studentsCrud/StudentsCrudDocuments"),
    name: "students-documents",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["students"],
      title: "Documentos",
      tabs: studentsRoutesTabs,
      serviceName: "documents",
    },
  },

  {
    path: "students/:id/files",
    component: () => import("@/modules/cardioguard-admin/views/studentsCrud/StudentsCrudFiles"),
    name: "students-files",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["students"],
      title: "Documentación",
      tabs: studentsRoutesTabs,
      serviceName: "files",
    },
  },
];
