import permissions from "@/router/userPermissions";

import crudUpdateMd from "@/components/crud-md/actions/Edit";
import clientsCentersRoutesTabs from "@/modules/cardioguard-admin/router/centersRoutesTabs";

export default [
  {
    path: "centers",
    name: "centers",
    component: () => import("@/modules/cardioguard-admin/views/centersCrud"),
    meta: { auth: permissions.scopes.admin, serviceName: "centers", title: "Centros" },
  },
  {
    path: "centers/:id/edit",
    name: "centersEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["centers"],
      serviceName: "centers",
      tabs: clientsCentersRoutesTabs,
      title: "Centro",
    },
  },
  {
    path: "centers/:id/contacts",
    component: () => import("@/modules/cardioguard-admin/views/centersCrud/CentersCrudContacts"),
    name: "centers-contacts",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["centers"],
      tabs: clientsCentersRoutesTabs,
      title: "Contactos",
      serviceName: "contacts",
    },
  },
  {
    path: "centers/:id/notices",
    component: () => import("@/modules/cardioguard-admin/views/centersCrud/CentersCrudNotices"),
    name: "centers-clientNotices",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["clients", "centers"],
      tabs: clientsCentersRoutesTabs,
      title: "Avisos",
      serviceName: "client-notices",
    },
  },
];
