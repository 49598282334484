import crudUpdateMd from "@/components/crud-md/actions/Edit";
import permissions from "@/router/userPermissions";

export default [
  {
    path: "documents",
    name: "documents",
    component: () => import("@/modules/cardioguard-admin/views/BusinessGroupsCrud"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "documents",
      title: "Documentos",
    },
  },
  {
    path: "documents/:id",
    name: "documentsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["documents"],
      serviceName: "documents",
      title: "Documento",
    },
  },
];
