import { crudMdActions } from "@/services/crud-md-actions";
import { getListActions } from "@/services/crud-md-actions/getListActions";
import { createActions } from "@/services/crud-md-actions/createActions";
import { importActions } from "@/services/crud-md-actions/importActions";
import apiClient from "../../../services/ApiClient";

const name = "training-course-inscriptions";

export default {
  name,
  ...crudMdActions(name),
  tab: { getListActions, createActions, importActions },
  regenerateDocs: trainingCourseInscriptionId => {
    return apiClient.post(
      `/training-course-inscriptions/${trainingCourseInscriptionId}/regenerateCertificates`
    );
  },
};
