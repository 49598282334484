import permissions from "@/router/userPermissions";

import IntranetTabs from "@/layouts/intranet-tabs/Index.vue";

import clientNoticesRoutes from "@/modules/cardioguard-admin/router/clientNoticesRoutes";
import clientsRoutes from "@/modules/cardioguard-admin/router/clientsRoutes";
import documentsRoutes from "@/modules/cardioguard-admin/router/DocumentsRoutes";
import filesRoutes from "@/modules/cardioguard-admin/router/filesRoutes";
import trainingCoursesRoutes from "@/modules/cardioguard-admin/router/trainingCoursesRoutes";
import trainingCourseInscriptionsRoutes from "@/modules/cardioguard-admin/router/trainingCourseInscriptionsRoutes";
import trainingCourseSessionsRoutes from "@/modules/cardioguard-admin/router/trainingCourseSessionsRoutes";
import studentsRoutes from "@/modules/cardioguard-admin/router/studentsRoutes";
import contactsRoutes from "@/modules/cardioguard-admin/router/contactsRoutes";
import settingsRoutes from "@/modules/cardioguard-admin/router/settingsRoutes";
import usersRoutes from "@/modules/cardioguard-admin/router/usersRoutes";

export default [
  {
    path: "/admin",
    component: IntranetTabs,
    children: [
      {
        path: "",
        component: () => import("@/views/Dashboard.vue"),
        name: "dashboard",
        meta: {
          auth: permissions.scopes.admin,
          title: "Panel de control",
          hideBreadcrumb: true,
          serviceName: "dashboard",
        },
      },
      ...clientNoticesRoutes,
      ...clientsRoutes,
      ...documentsRoutes,
      ...filesRoutes,
      ...trainingCoursesRoutes,
      ...trainingCourseInscriptionsRoutes,
      ...trainingCourseSessionsRoutes,
      ...studentsRoutes,
      ...contactsRoutes,
      ...settingsRoutes,
      ...usersRoutes,
    ],
  },
];
