import permissions from "@/router/userPermissions";

import crudUpdateMd from "@/components/crud-md/actions/Edit";

import businessGroupsRoutes from "@/modules/cardioguard-admin/router/businessGroupsRoutes";
import centersRoutes from "@/modules/cardioguard-admin/router/centersRoutes";
import clientsRoutesTabs from "@/modules/cardioguard-admin/router/clientsRoutesTabs";

export default [
  {
    path: "clients",
    component: () => import("@/modules/cardioguard-admin/views/clientsCrud"),
    name: "clients",
    meta: { auth: permissions.scopes.admin, title: "Clientes", serviceName: "clients" },
  },
  {
    path: "clients/:id/edit",
    component: crudUpdateMd,
    name: "clientsEdit",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["clients"],
      tabs: clientsRoutesTabs,
      title: "Datos del cliente",
      serviceName: "clients",
    },
  },
  {
    path: "clients/:id/contacts",
    component: () => import("@/modules/cardioguard-admin/views/clientsCrud/ClientsCrudContacts"),
    name: "clients-contacts",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["clients"],
      tabs: clientsRoutesTabs,
      title: "Contactos",
      serviceName: "contacts",
    },
  },
  {
    path: "clients/:id/notices",
    component: () => {
      return import("@/modules/cardioguard-admin/views/clientsCrud/ClientsCrudClientNotices");
    },
    name: "clients-clientNotices",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["clients"],
      tabs: clientsRoutesTabs,
      title: "Avisos",
      serviceName: "client-notices",
    },
  },

  ...businessGroupsRoutes,
  ...centersRoutes,
];
